
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { EventDetailClient, UserMatchClient } from '@/services/Services';

@Options({})
export default class eventStats extends Vue {

    stats: OM.EventStatisticsVm = null;
    matches: OM.UserMatch[] = [];

    filtersFn = {
        users: (row: OM.UserMatch, input) => {
            return row.users.some(c => c.text && c.text.toLowerCase().indexOf(input.toLowerCase()) > -1);
        },
        approvals: (row: OM.UserMatch, input) => {
            return row.approvals.some(c => c.text && c.text.toLowerCase().indexOf(input.toLowerCase()) > -1);
        },
    }

    created(){
        EventDetailClient.getEventStatistics(this.$route.params.identifier as string)
        .then(x => {
            this.stats = x;
        })
        UserMatchClient.getMatchesOfEvent(this.$route.params.identifier as string)
        .then(x => {
            this.matches = x;
        })
    }

}
